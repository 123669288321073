@font-face {
    font-family: 'Avenir Next Medium';
    src: url('AvenirNext-Medium.eot');
    src: local('Avenir Next Demi'), local('AvenirNext-Medium'),
        url('AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-Medium.woff2') format('woff2'),
        url('AvenirNext-Medium.woff') format('woff'),
        url('AvenirNext-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Regular';
    src: local('Avenir Next Regular'), local('AvenirNext-Regular'),
        url('AvenirNext-Regular.woff2') format('woff2'),
        url('AvenirNext-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Light';
    src: url('AvenirNext-Light.eot');
    src: local('Avenir Next Light'), local('AvenirNext-Light'),
        url('AvenirNext-Light.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-Light.woff2') format('woff2'),
        url('AvenirNext-Light.woff') format('woff'),
        url('AvenirNext-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Bold';
    src: url('AvenirNext-Bold.eot');
    src: local('☞Avenir Next Bold'), local('AvenirNext-Bold'),
        url('AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-Bold.woff2') format('woff2'),
        url('AvenirNext-Bold.woff') format('woff'),
        url('AvenirNext-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Heavy';
    src: local('☞Avenir Next Heavy'), local('AvenirNext-Heavy'),
        url('AvenirNext-Heavy.woff2') format('woff2'),
        url('AvenirNext-Heavy.woff') format('woff');
    font-weight: 1000;
    font-style: normal;
    font-display: swap;
}